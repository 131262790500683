var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('Form',{ref:"dataForm",attrs:{"model":_vm.formData,"label-width":80,"inline":""}},[_c('timeFilter',{ref:"timeFilter",attrs:{"formData":_vm.formData}}),_c('FormItem',[_c('Button',{staticStyle:{"margin-right":"20px","background-color":"#00A0E9"},attrs:{"type":"info"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("查询")])],1)],1),_c('GeminiScrollbar',{staticStyle:{"height":"calc(100% - 150px)","overflow":"auto"}},[_c('Table',{staticClass:"table-list",attrs:{"loading":_vm.loading,"columns":_vm.columns,"data":_vm.data.list,"no-data-text":"没有符合查询条件的数据"},scopedSlots:_vm._u([{key:"addTime",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatTime")(row.addTime))+" ")]}},{key:"state",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("getState")(row.state))+" ")]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("getType")(row.type))+" ")]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Button',{staticClass:"action-btn",attrs:{"type":"info","size":"small"},on:{"click":function($event){return _vm.viewItem(row)}}},[_vm._v("查看详情")])]}}])})],1),_c('div',{staticClass:"page-nav"},[_c('Page',{attrs:{"show-total":"","total":_vm.data.total,"current":_vm.pageNum,"page-size":_vm.pageSize},on:{"update:current":function($event){_vm.pageNum=$event},"on-change":_vm.changPageNum}})],1),_c('lookView',{attrs:{"showLookView":_vm.showLookView,"checkRowData":_vm.checkRowData},on:{"update:showLookView":function($event){_vm.showLookView=$event},"update:show-look-view":function($event){_vm.showLookView=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }