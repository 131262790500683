




























import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
import timeFilter from './timeFilter.vue'
import lookView from './call_recond_detials.vue'
const columns = [
    {
        title: '预警内容',
        key: 'content',
    },
    {
        title: '预警类型',
        slot: 'type',
    },
    {
        title: '发布内容创建时间',
        slot: 'addTime',
    },
    {
        title: '状态',
        slot: 'state',
    },
    {
        title: '操作',
        slot: 'action',
        // width: 400
    },
]
export default Vue.extend({
    components: {
        timeFilter,
        lookView,
    },
    data() {
        return {
            loading: false,
            pageNum: 1,
            pageSize: 10,
            columns,
            data: {
                total: 0,
                list: [],
            },
            formData: {},
            showLookView: false,
            checkRowData: {},
            interval: 0,
        }
    },
    methods: {
        getData() {
            this.loading = true
            const { pageNum, pageSize } = this
            const timeFilterRef: any = this.$refs.timeFilter
            // let params = {}
            // if (timeFilterRef) {
            //     const formList = timeFilterRef.getData()
            //     this.formData = { ...this.formData, ...formList}
            //     params = { pageNum, pageSize, ...formList}
            // } else {
            //     params = { pageNum, pageSize,}
            // }
            const formList = timeFilterRef.getData()
            this.formData = { ...this.formData, ...formList}
            const params = { pageNum, pageSize, ...formList}
            request.get('/api/respond/post_message/page', params)
            .then((data) => {
                this.data = data
                this.loading = false
            })
        },
        startInterval() {
            this.interval = window.setInterval(() => {
                const stateArray = this.data.list.map((e: any) => e.state)
                if (stateArray.indexOf(0) > -1) {
                    this.getData()
                } else {
                    this.stopInterval()
                }
            }, 10 * 1000)
        },
        stopInterval() {
            if (!this.interval) {
                return
            }
            clearInterval(this.interval)
            this.interval = 0
        },
        viewItem(rowData: any) {
            this.showLookView = true
            this.checkRowData = rowData
        },
        /* 改变pageNum */
        changPageNum(num: number) {
            this.pageNum = num
            this.getData()
        },
    },
    mounted() {
        this.getData()
        this.startInterval()
    },
    filters: {
        formatTime(value: any) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
        },
        getState(value: number) {
            if (value === 0) {
                return '正在发布'
            }
            return '发布完成'
        },
        getType(value: number) {
            if (value === 1) {
                return '自动发布'
            }
            return '手动发布'
        },
    },
})
