





































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
const columns = [
    {
        title: '发布对象',
        key: 'personName',
    },
    {
        title: '接收终端号码',
        key: 'personNumber',
    },
    {
        title: '渠道',
        slot: 'mode',
    },
    {
        title: '状态',
        slot: 'state',
    },
    {
        title: '备注',
        key: 'remarks',
    },
    {
        title: '发布时间',
        slot: 'addTime',
    },
]
export default Vue.extend({
    props: {
        showLookView: {
            type: Boolean,
        },
        checkRowData: {
            type: Object,
        },
    },
    watch: {
        showLookView() {
            if (this.showLookView) {
                this.init()
                this.startInterval()
            }
        },
    },
    data() {
        return {
            loading: false,
            pageNum: 1,
            pageSize: 10,
            columns,
            detailsData: {
                total: 0,
                list: [],
            },
            statisticsData: {},
            recondData: {
                state: 0,
            },
            interval: 0,
        }
    },
    methods: {
        init() {
            this.getRecondItem()
            this.getStatistics()
            this.getRecondDetails()
        },
        startInterval() {
            this.interval = window.setInterval(() => {
                if (this.recondData.state === 0) {
                    this.init()
                } else {
                    this.stopInterval()
                }
            }, 2 * 1000)
        },
        stopInterval() {
            if (!this.interval) {
                return
            }
            clearInterval(this.interval)
            this.interval = 0
        },
        getRecondItem() {
            return request.get(`/api/respond/post_message/${this.checkRowData.id}`, null)
            .then((data) => {
                // console.log(data)
                this.recondData = data
            })
        },
        getStatistics() {
            request.get(`/api/respond/post_detail/statistics/${this.checkRowData.id}`, null)
            .then((data) => {
                // console.log(data)
                let sum = 0
                if (data.state0) {
                    sum += data.state0
                } else {
                    data.state0 = 0
                }
                if (data.state1) {
                    sum += data.state1
                } else {
                    data.state1 = 0
                }
                if (data.state2) {
                    sum += data.state2
                } else {
                    data.state2 = 0
                }
                data.success = data.state1 / sum * 100
                data.published = (data.state1 + data.state2) / sum * 100
                this.statisticsData = data
            })
        },
        getRecondDetails() {
            this.loading = true
            const { pageNum, pageSize } = this
            const params = { pageNum, pageSize, postMessageId: this.checkRowData.id}
            request.get('/api/respond/post_detail/page', params)
            .then((data) => {
                // console.log(data)
                for (const i of data.list) {
                    if (i.mode === 1) {
                        i.personNumber = i.mobileNum
                    }
                    if (i.mode === 2) {
                        i.personNumber = i.telNum
                    }
                    if (i.mode === 4) {
                        i.personNumber = i.faxNum
                    }
                    if (i.mode === 5) {
                        i.personName = '城固微信公众号'
                        i.personNumber = '关注者'
                    }
                }
                this.detailsData = data
                this.loading = false
            })
        },
        /* 改变pageNum */
        changPageNum(num: number) {
            this.pageNum = num
            this.getRecondDetails()
        },
        cancelSubmit() {
            this.stopInterval()
            this.$emit('update:showLookView', false)
        },
    },
    filters: {
        formatTime(value: any) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
        },
        getMode(value: number) {
            if (value === 1) {
                return '短信'
            }
            if (value === 2) {
                return '外呼'
            }
            if (value === 3) {
                return '邮件'
            }
            if (value === 4) {
                return '传真'
            }
            if (value === 5) {
                return '微信'
            }
        },
        getState(value: number) {
            if (value === 1) {
                return '已发布'
            }
            if (value === 2) {
                return '发布失败'
            }
            return '正在发布'
        },
    },
})
